import YouTubePlayer from 'youtube-player'
import gsap from 'gsap'

export default class Play3r {
  constructor(el, options = {}) {
    if (!el) return

    // DOM@
    this.DOM = {
      el,
      play3rYouTube: el.querySelector('.play3r__youtube')
    }

    this.options = {
      buttonAnimationScale: 1.1,    // Scale maximum du bouton
      buttonMoveStrength: 0.3,      // Force du mouvement (0-1)
      buttonMaxMove: 20,            // Déplacement maximum en pixels
      buttonLerpEase: 0.15,         // Facteur de lissage pour le lerp

      buttonMaxMove: 20,            // Déplacement maximum en pixels
      buttonMoveEase: 'expo.out',   // Type d'easing
      ...options
    }
    
    if (this.DOM.play3rYouTube) {
      this.options.youtubeId = this.DOM.play3rYouTube.getAttribute('data-id')
    }

    // Volume range option
    // this.volumeSlider = this.DOM.el.querySelector('.volume-slider')
    // this.volumeSlider.addEventListener('input', this.volumeAction.bind(this))

    // Settings
    this.isFullScreen = false

    // DOM
    this.DOM.buttonStates = this.DOM.el.querySelector('.button-states')
    this.DOM.toggleButton = this.DOM.el.querySelector('.toggle-action')
    this.DOM.stopButton = this.DOM.el.querySelector('.stop-action')
    this.DOM.progressBar = this.DOM.el.querySelector('.progress-bar')
    this.DOM.progressBarFill = this.DOM.el.querySelector('.progress-bar__fill')
    this.DOM.durationCurrent = this.DOM.el.querySelector('.duration-current')
    this.DOM.durationTotal = this.DOM.el.querySelector('.duration-total')
    this.DOM.soundButton = this.DOM.el.querySelector('.sound-action')
    this.DOM.fullscreenButton = this.DOM.el.querySelector('.fullscreen-action')
    this.DOM.cover = this.DOM.el.querySelector('.play3r__cover')

    if (this.DOM.play3rYouTube) {
      this.initYouTubePlayer()
    } else {
      this.video = this.DOM.el.querySelector('video')
    }

    this.addEventListeners()
    // this.addMouseEvents()
  }

  /**
   * YouTube
   */

  initYouTubePlayer() {
    this.player = YouTubePlayer(this.DOM.play3rYouTube, {
      videoId: this.options.youtubeId,
      playerVars: {
        controls: 0,                      // Désactiver les contrôles YouTube
        modestbranding: 1,                // Réduire le logo YouTube
        rel: 0,                           // Ne pas afficher de vidéos similaires à la fin
        showinfo: 0,                      // Masquer les infos
        iv_load_policy: 3,                // Désactiver les annotations
        disablekb: 1,                     // Désactiver les raccourcis clavier
        loop: 0,                          // Désactiver la lecture en boucle
        playlist: this.options.youtubeId  // Nécessaire quand loop est spécifié
      }
    })

    this.player.on('stateChange', (event) => {
      if (event.data === 0) { // YT.PlayerState.ENDED
        // Force l'arrêt complet de la vidéo
        this.player.stopVideo()
        // Remet la vidéo au début
        this.player.seekTo(0)
        // Met à jour l'état de l'interface
        this.DOM.el.setAttribute('data-status', 'stopped')
        if (this.youtubeInterval) {
          clearInterval(this.youtubeInterval)
        }
        this.endStatus()
      }
    })

    this.player.getIframe().then(iframe => {
      this.video = iframe
      this.setDurationInfos()
    })
  }

  onYouTubeStateChange(event) {
    const state = event.data

    if (state === 1) {          // YT.PlayerState.PLAYING
      this.setStatePlay()
    } else if (state === 2) {   // YT.PlayerState.PAUSED
      this.setStatePause()
    } else if (state === 0) {   // YT.PlayerState.ENDED
      if (this.youtubeInterval) {
        clearInterval(this.youtubeInterval)
      }
      
      this.endStatus()
      this.player.stopVideo()
    }
  }

  updateYouTubeCurrentTime() {
    if (this.DOM.play3rYouTube) {
      Promise.all([this.player.getCurrentTime(), this.player.getDuration()])
        .then(([currentTime, duration]) => {
          this.DOM.durationCurrent.innerHTML = this.formatTime(currentTime)
          const progress = (currentTime / duration) * 100
          this.DOM.progressBarFill.style.setProperty('--progress', progress * 0.01)
        })
    }
  }
  
  formatTime(time) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)

    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }

  /**
   * Listeners
   */

  addEventListeners() {
    if (this.DOM.cover) {
      this.DOM.cover.addEventListener('click', this.toggleAction.bind(this))
    }
    
    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.addEventListener('click', this.toggleAction.bind(this))
    }

    if (this.DOM.stopButton) {
      this.DOM.stopButton.addEventListener('click', this.stopAction.bind(this))
    }
		
    // Events for play/pause with keys    
    if (this.DOM.play3rYouTube) {
      this.player.on('stateChange', this.onYouTubeStateChange.bind(this))
    } else {
      this.video.addEventListener('timeupdate', this.timeUpdate.bind(this))
      this.video.addEventListener('ended', this.endStatus.bind(this))
      this.video.addEventListener('play', this.setStatePlay.bind(this))
      this.video.addEventListener('pause', this.setStatePause.bind(this))
    }

    if (this.DOM.progressBar) {
      this.DOM.progressBar.addEventListener('click', this.progressAction.bind(this))
    }

    if (this.DOM.soundButton) {
      this.soundButton.addEventListener('click', this.toggleSoundAction.bind(this))
    }

    if (this.DOM.fullscreenButton) {
      this.DOM.fullscreenButton.addEventListener('click', this.fullscreenAction.bind(this))
    }

    document.addEventListener('fullscreenchange', () => this.isFullScreen = !this.isFullScreen)
    this.DOM.el.addEventListener('dblclick', this.fullscreenAction.bind(this))

    this

    setTimeout(() => this.setDurationInfos(), 50)
  }

  removeEventListeners() {
    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.removeEventListener('click', this.toggleAction.bind(this))
    }
    
    if (this.DOM.stopButton) {
      this.DOM.stopButton.removeEventListener('click', this.stopAction.bind(this))
    }

    if (this.DOM.play3rYouTube) {
      this.video.removeEventListener('timeupdate', this.timeUpdate.bind(this))
      this.video.removeEventListener('ended', this.endStatus.bind(this))
      this.video.removeEventListener('play', this.setStatePlay.bind(this))
      this.video.removeEventListener('pause', this.setStatePause.bind(this))
    } else {
      this.player.off('stateChange', this.onYouTubeStateChange.bind(this))
    }

    if (this.DOM.progressBar) {
      this.DOM.progressBar.removeEventListener('click', this.progressAction.bind(this))
    }

    if (this.DOM.soundButton) {
      this.DOM.soundButton.removeEventListener('click', this.toggleSoundAction.bind(this))
    }

    if (this.DOM.fullscreenButton) {
      this.DOM.fullscreenButton.removeEventListener('click', this.fullscreenAction.bind(this))
    }

    document.removeEventListener('fullscreenchange', () => this.isFullScreen = !this.isFullScreen)
    this.DOM.el.removeEventListener('dblclick', this.fullscreenAction.bind(this))
  }

  /**
   * Mouse events
   */

  addMouseEvents() {
    this.moveX = gsap.quickTo(this.DOM.buttonStates, 'x', { duration: 1, ease: this.options.buttonMoveEase })
    this.moveY = gsap.quickTo(this.DOM.buttonStates, 'y', { duration: 1, ease: this.options.buttonMoveEase })
    this.scaleX = gsap.quickTo(this.DOM.buttonStates, 'scaleX', { duration: 1, ease: this.options.buttonMoveEase })
    this.scaleY = gsap.quickTo(this.DOM.buttonStates, 'scaleY', { duration: 1, ease: this.options.buttonMoveEase })

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)

    this.DOM.el.addEventListener('mouseenter', this.onMouseEnter)
    this.DOM.el.addEventListener('mousemove', this.onMouseMove)
    this.DOM.el.addEventListener('mouseleave', this.onMouseLeave)
  }

  removeMouseEvents() {
    this.DOM.el.removeEventListener('mouseenter', this.onMouseEnter)
    this.DOM.el.removeEventListener('mousemove', this.onMouseMove)
    this.DOM.el.removeEventListener('mouseleave', this.onMouseLeave)

    this.moveX = null
    this.moveY = null
    this.scaleX = null
    this.scaleY = null
  }


  onMouseEnter() {
    this.moveX(0)
    this.moveY(0)
    this.scaleX(1)
    this.scaleY(1)
  }

  onMouseMove(e) {
    const { top, left, height, width } = this.DOM.el.getBoundingClientRect()
    const halfW = width / 2
    const halfH = height / 2
    const mouseX = e.x - left
    const mouseY = e.y - top

    const x = gsap.utils.interpolate(-halfW, halfW, mouseX / width)
    const y = gsap.utils.interpolate(-halfH, halfH, mouseY / height)

    // Appliquer le mouvement avec quickTo
    this.moveX(x)
    this.moveY(y)
    this.scaleX(1.5)
    this.scaleY(1.5)
  }
  
  onMouseLeave(e) {
    this.moveX(0)
    this.moveY(0)
    this.scaleX(1)
    this.scaleY(1)
  }

  /**
   * Events
   */

  toggleAction(e) {
    e && e.preventDefault()

    if (this.DOM.play3rYouTube) {
      this.player.getPlayerState().then(state => {
        // On ajoute l'état 0 (ended) dans la condition
        if (state === 2 || state === 5 || state === 0) {
          this.setStatePlay()
          this.youtubeInterval = setInterval(() => this.updateYouTubeCurrentTime(), 500)
        } else {
          this.setStatePause()
          if (this.youtubeInterval) clearInterval(this.youtubeInterval)
        }
      })
    } else {
      if (this.video.paused) {
        this.setStatePlay()
      } else {
        this.setStatePause()
      }
    }
  }

  setStatePlay() {
    this.DOM.el.setAttribute('data-status', 'playing')
    
    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.innerHTML = 'Pause'
    }

    if (this.DOM.play3rYouTube) {
      this.player.playVideo()
    } else {
      this.video.play()
    }
  }

  setStatePause() {
    this.DOM.el.setAttribute('data-status', 'paused')
    
    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.innerHTML = 'Play'
    }

    if (this.DOM.play3rYouTube) {
      this.player.pauseVideo()
    } else {
      this.video.pause()
    }
  }

  stopAction(e) {
    e && e.preventDefault()

    this.DOM.el.setAttribute('data-status', 'stopped')

    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.innerHTML = 'Play'
    }

    if (this.DOM.play3rYouTube) {
      this.player.stopVideo()
    } else {
      this.video.pause()
      this.video.currentTime = 0
    }
  }

  volumeAction() {
    this.video.volume = this.volumeSlider.value
  }

  toggleSoundAction(e) {
    e && e.preventDefault()

    if (this.DOM.play3rYouTube) {
      this.player.isMuted().then(isMuted => {
        if (isMuted) {
          this.player.unMute()
          this.DOM.soundButton.innerHTML = 'Sound: On'
        } else {
          this.player.mute()
          this.DOM.soundButton.innerHTML = 'Sound: Off'
        }
      })
    } else {
      if (this.video.muted) {
        this.video.muted = false
        this.DOM.soundButton.innerHTML = 'Sound: On'
      } else {
        this.video.muted = true
        this.DOM.soundButton.innerHTML = 'Sound: Off'
      }
    }
  }

  setDurationInfos() {
    if (this.DOM.play3rYouTube) {
      this.player.getDuration().then(duration => {
        this.DOM.durationTotal.innerHTML = this.formatTime(duration)
      })
      
      this.player.getCurrentTime().then(currentTime => {
        this.DOM.durationCurrent.innerHTML = this.formatTime(currentTime)
      })
    } else {
      let currentMinutes = Math.floor(this.video.currentTime / 60)
      let currentSeconds = Math.floor(this.video.currentTime - currentMinutes * 60)
      let durationMinutes = Math.floor(this.video.duration / 60)
      let durationSeconds = Math.floor(this.video.duration - durationMinutes * 60)

      this.DOM.durationCurrent.innerHTML = `${currentMinutes}:${currentSeconds < 10 ? '0' + currentSeconds : currentSeconds}`
      this.DOM.durationTotal.innerHTML = `${durationMinutes}:${durationSeconds}`
    }
  }

  timeUpdate() {
    // Update progress bar
    let progress = (this.video.currentTime / this.video.duration) * 100

    if (this.DOM.progressBarFill) {
      this.DOM.progressBarFill.style.setProperty('--progress', progress * 0.01)
    }

    // Update duration infos
    this.setDurationInfos()
  }

  progressAction(e) {
    let progressWidth = this.DOM.progressBar.clientWidth
    let clickedPosition = e.offsetX
    let clickedTime

    if (this.DOM.play3rYouTube) {
      this.player.getDuration().then(duration => {
        clickedTime = (clickedPosition / progressWidth) * duration
        this.player.seekTo(clickedTime)
      })
    } else {
      clickedTime = (clickedPosition / progressWidth) * this.video.duration
      this.video.currentTime = clickedTime
    }
  }

  endStatus() {
    this.DOM.el.setAttribute('data-status', 'stopped')

    // Reset button
    if (this.DOM.toggleButton) {
      this.DOM.toggleButton.innerHTML = 'Play'
    }

    // Reset progressBarFill
    if (this.DOM.progressBarFill) {
      this.DOM.progressBarFill.style.setProperty('--progress', 0)
    }

    // Reset duration current 
    if (this.DOM.durationCurrent) {
      this.DOM.durationCurrent.innerHTML = '0:00'
    }

    // Pour YouTube, on s'assure que le temps est bien remis à 0
    if (this.DOM.play3rYouTube) {
      this.player.seekTo(0)
    }
  }

  fullscreenAction(e) {
    e && e.preventDefault()

    if (this.isFullScreen) {
      this.closeFullScreen()
    } else {
      this.openFullScreen()
    }
  }

  openFullScreen() {
    if (this.DOM.el.requestFullscreen) {
      this.DOM.el.requestFullscreen()
    } else if (this.DOM.el.webkitRequestFullscreen) { /* Safari */
      this.DOM.el.webkitRequestFullscreen()
    } else if (this.DOM.el.msRequestFullscreen) { /* IE11 */
      this.DOM.el.msRequestFullscreen()
    }
  }

  closeFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen()
    }
  }

  /**
   * Destroy
   */

  destroy() {
    if (!this.DOM) return

    this.removeEventListeners()
    this.removeMouseEvents()

    if (this.DOM.play3rYouTube) {
      if (this.youtubeInterval) {
        clearInterval(this.youtubeInterval)
      }
      
      this.player.destroy()
    } else {
      this.video.pause()
      this.video.currentTime = 0
    }
  }
}
