import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'
import TestimoniesSlider from '../modules/testimonies-slider'
import Play3r from '../modules/Play3r'

export default class PageHome extends Renderer {

  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      scrollingText : viewStorage.current.querySelector('.Scrolling__text'),
      partners: viewStorage.current.querySelector('.Partners__swiper .swiper'),
      testimoniesSliderSection: viewStorage.current.querySelector('.TestimoniesSlider'),

      heroVideo: viewStorage.current.querySelector('.hero-video'),
      projectsVideo: viewStorage.current.querySelector('.projects-video'),
    }

    this.init()
  }

  init() {
    if (this.DOM.scrollingText) {
      this.initScrollingText()
    }

    if (this.DOM.partners) {
      this.initPartners()
    }

    if (this.DOM.testimoniesSliderSection) {
      this.testimoniesSlider = new TestimoniesSlider(this.DOM.testimoniesSliderSection)
    }

    if (this.DOM.heroVideo) {
      this.play3rHero = new Play3r(this.DOM.heroVideo)
    }
    
    if (this.DOM.projectsVideo) {
      this.play3rProjects = new Play3r(this.DOM.projectsVideo)
    }
  }

  initScrollingText() {
    const { scrollingText } = this.DOM
    const title = scrollingText.querySelector('.Scrolling__text__title')

    let mm = gsap.matchMedia();
    mm.add("(min-width: 768px)", () => {
      this.scrollingTextTL = gsap.timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: title,
          start: 'center center',
          end: 'bottom bottom',
          endTrigger: scrollingText,
          scrub: true,
          // markers: isDEVMODE
        }
      })
      .fromTo(title.querySelector('h2'), { yPercent: 0, scale: 1 }, { yPercent: 200, scale: 0.3 })
    })
  }

  initPartners() {
    const { partners } = this.DOM
    this.partnersSwiper = new WknSwiper(partners)
  }

  onLeaveCompleted() {
    if (this.scrollingTextTL) {
      this.scrollingTextTL.kill(true)
    }

    if (this.testimoniesSlider) {
      this.testimoniesSlider.destroy()
    }

    if (this.play3rHero) {
      this.play3rHero.destroy()
    }

    if (this.play3rProjects) {
      this.play3rProjects.destroy()
    }
  }
}
