import { isDEVMODE } from '../_globals'
import Plyr from 'plyr'

export default class WknPlyr {

  constructor(container) {

    this.DOM = {
      plyrContainer: container,
      video: container.querySelector('[data-plyr]'),
      btnPlay: container.querySelector('.plyr__play'),
      videoCover: container.querySelector('.plyr__cover')
    }

    if (!this.DOM.video) return

    this.state = {
      isPlaying: false
    }

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('New WknPlyr')

    this.createPlyr()
    this.addEvents()

  }

  createPlyr() {

    const { video } = this.DOM

    this.plyr = new Plyr(video, {
      controls: ['play', 'progress', 'mute', 'fullscreen'],
      youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
    })

  }

  addEvents() {

    const { videoCover } = this.DOM

    this.onCoverClick = this.onCoverClick.bind(this)
    videoCover.addEventListener('click', this.onCoverClick)

    this.onReady = this.onReady.bind(this)
    this.plyr.on('ready', this.onReady)

    this.onPlaying = this.onPlaying.bind(this)
    this.plyr.on('playing', this.onPlaying)

    this.onSeeking = this.onSeeking.bind(this)
    this.plyr.on('seeking', this.onSeeking)

    this.onEnded = this.onEnded.bind(this)
    this.plyr.on('ended', this.onEnded)
  }

  onCoverClick(e) {
    this.plyr.play()
  }

  onReady(event) {
    const { currentTarget } = event
    setTimeout(() => currentTarget.classList.add('plyr--hide-controls'), 150)
  }

  onPlaying(event) {
    const { currentTarget } = event
    if (currentTarget.classList.contains('plyr--seeking')) currentTarget.classList.remove('plyr--seeking')
    if (currentTarget.classList.contains('plyr--ended')) currentTarget.classList.remove('plyr--ended')
  }

  onSeeking(event) {
    const { currentTarget } = event
    if (!currentTarget.classList.contains('plyr--seeking')) currentTarget.classList.add('plyr--seeking')
  }

  onEnded(event) {
    const { currentTarget } = event
    currentTarget.classList.add('plyr--hide-controls')
    currentTarget.classList.add('plyr--ended')
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy WknPlyr')

    const { plyr } = this
    const { videoCover } = this.DOM

    videoCover.removeEventListener('click', this.onCoverClick)
    this.plyr.off('ready', this.onReady)
    this.plyr.off('playing', this.onPlaying)
    this.plyr.off('seeking', this.onSeeking)
    this.plyr.off('ended', this.onEnded)
    plyr.destroy()

  }
}
