import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Play3r from '../modules/Play3r'
import TestimoniesSlider from '../modules/testimonies-slider'

export default class PagePedagogie extends Renderer {

  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {

    this.DOM = {
      projects : viewStorage.current.querySelector('.projects'),
      testimoniesSliderSection: viewStorage.current.querySelector('.TestimoniesSlider'),

      projectsVideo: viewStorage.current.querySelector('.projects-video'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Pedagogie`)
    if (isDEVMODE) console.log(viewStorage.current)

    if (this.DOM.projectsVideo) {
      this.play3rProjects = new Play3r(this.DOM.projectsVideo)
    }

    if (this.DOM.testimoniesSliderSection) {
      this.testimoniesSlider = new TestimoniesSlider(this.DOM.testimoniesSliderSection)
    }
  }

  onLeaveCompleted() {
    this.projectsPlyr && this.projectsPlyr.destroy()
    if (this.testimoniesSlider) {
      this.testimoniesSlider.destroy()
    }

    if (this.play3rProjects) {
      this.play3rProjects.destroy()
    }
  }
}
